import React, { Component } from 'react'
import './updateversion.scss';
import { isEnv } from "@/config/index";
import { urlResolver } from '@/utils/commons';

export class updateversion extends Component {
  state = {
  }
  componentDidMount(){
  }
  render() {
    return (
      <div className='updateversion_container'>
        <div className='updateversion_box'>
          {/* <img src="" alt=""/> */}
          <img src={require('../../assets/img/error.png')} alt=""/>
          <h3>当前版本过低</h3>
          <p>当前微信版本过低，请更新微信！</p>
        </div>
      </div>
    )
  }
}

export default updateversion
