import React, { Component } from 'react';
import { queryUserOrderDetail } from '@/api/api';
import { isEnv } from "@/config/index";
import { urlResolver } from '@/utils/commons';
import { checkisEnv } from '@/utils/utils'
import classnames from 'classnames';
import './orderDetails.scss';
import qs from 'qs'
import Modal from '../../components/modal/modal'
export class orderDetails extends Component {
  state = {
    miniarr: [],
    largearr: [],
    Num: 0,
    List:[],
    arrs:[],
    id:'',
    times:'',
    Refund:false,
    Pickup:false,
  }
  componentDidMount() {
    let url
    if (!isEnv) {
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InljMzYyNDEwNTYiLCJ1c2VyTW9iaWxlIjoiMTgyNTgxNzU4NDAiLCJ1c2VySWQiOjE1ODY4NDR9.0kE4swArLn29P40K_xzyL5bfmtv_CjcalY1T2s579-I";
      urlResolver(`?token=${token}`)
    } else {
      url = this.props.location.search;
      urlResolver(url);
    }
    this.setState({
      id:this.props.location.state?this.props.location.state:urlResolver(url).id
    })
    this.initialize(this.props.location.state?this.props.location.state:urlResolver(url).id)
    
  }
  initialize(id) {
    queryUserOrderDetail({
      id:id,
    }).then(({ success, data }) => {
      if (success) {
        this.setState({
          List: data
        })
        this.status(data)
      }
    })
  }
  setMoney() {
    const {List} = this.state
    const params = {
      activityId: this.state.id,
      sourceType: 'again',
      money:List.money
    }
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          window.my.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
        case 'weapp_mini':
          window.wx.miniProgram.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
      }
    })
  }
  status(List) {
    if(List.status == 1){
      let present = Date.parse(new Date())
      let timestamp = new Date(List.createTime).getTime()
      let time = (timestamp+10*60*1000 -present ) / 1000
      let _this = this
      console.log(time)
      let s = setInterval(function(){
        time -=1
        if(time < 900) {
          let f = parseInt(time/60)
          let m = parseInt(time%60)
          if(f<=9) {
            f= '0' + f
          }
          if(m<=9) {
            m= '0' + m
          }
          _this.setState({
            times:'待支付 '+f+':'+m+'...'
          })
        }else{
          clearInterval(s)
          _this.setState({
            times:'超时关闭',
          })
        }
      }, 1000);
    }else if(List.status == 2) {
      return '已支付'
    }else if(List.status == 3){
      return '退款'
    }else { 
      return '超时关闭'
    }
  }
  setRefund() {
    this.setState({
      Refund:true
    })
  }
  confirmBtn() {
    this.setState({
      Refund:false 
    })
  }
  setpickup() {
    this.setState({
      Pickup:true
    })
  }
  confirmPickup() {
    this.setState({
      Pickup:false
    })
  }
  render() {
    const { List,times, Refund, Pickup } = this.state
    const verifyInfo = this.props.location.verifyInfo
    console.log('verifyInfo============',this.props.location,List)
    return (
      <div className="orderDetails">
        <div className="orderStatus">
          <div>{List.status == 1 ? times :this.status(List)}</div>
          <div className='pickup-btn' onClick={() => this.setpickup()}>取货码</div>
        </div>
        <div className="order_message">
          <div className="order_head">
            <div className="order_name font17_w">
              {List.tenantName}
            </div>
            <div className="order_sum font13">
              共{List.goodsNumber}件
            </div>
          </div>
          {
            List.marketUserOrderDetailList && List.marketUserOrderDetailList.length != 0 && List.marketUserOrderDetailList.map((item,index) => {
              return (
                <div className="order_item" key={index+item}>
                  <div className="imgage_text">
                    <img className="order_img" src={item.goodsImgUrl}/>
                    <div className="shop_details">
                      <div className="shop_details_name font13">
                        {item.goodsName}
                      </div>
                      <div className="money font15">
                        ￥{item.price.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="order_num font15">
                    {item.quantity}件
                  </div>
                </div>
              )
            })
          }
          <div className="border"></div>
          <div className="money_sum space font15">
            <div>商品总额</div>
            <div>￥{List.money &&List.money.toFixed(2)}</div>
          </div>
          <div className="money_sum space font15">
            <div>优惠券</div>
            <div className="not">暂无</div>
          </div>
          <div className="money_sum space font15">
            <div>配送费</div>
            <div className="not">暂无</div>
          </div>
          <div className="money_sum space font15">
            <div></div>
            <div className="order_money">
              合计:
              <div className="money_sums">￥{List.money && List.money.toFixed(2)}</div>
            </div>
          </div>

        </div>
        {/* "deliveryType"// 1-自提，2-外送 */}
        <div className="personal_details">
          <div className="title font17_w">{List.deliveryType == 1 ? '自提' : '收货'}信息</div>
          <div className="personal_details_item space">
            <div className="personal_name font15">
            {List.deliveryType == 1 ? '商家' : '收货'}地址
            </div>
            <div className="personal_status font159">
              {List.deliveryType == 1?List.selfLift:List.harvestAddress}
            </div>
          </div>
          <div className="personal_details_item space">
            <div className="personal_name font15">
            {List.deliveryType == 1 ? '收货' : '取货'}人
            </div>
            <div className="personal_status font139">
              {List.name}  {List.phoneNumber}
            </div>
          </div>
        </div>
        <div className="personal_details">
          <div className="title font17_w">订单信息</div>
          <div className="personal_details_item space">
            <div className="personal_name font15">
              订单编号
            </div>
            <div className="personal_status font139">
              {List.orderNo}
            </div>
          </div>
          <div className="personal_details_item space">
            <div className="personal_name font15">
              下单时间
            </div>
            <div className="personal_status font159">
             {List.createTime}
            </div>
          </div>
          <div className="personal_details_item space">
            <div className="personal_name font15">
              支付方式
            </div>
            <div className="personal_status font159">
             {List.payType==1?'支付宝':'微信'}
            </div>
          </div>
          <div className="personal_details_item space">
            <div className="personal_name font15">
              订单备注
            </div>
            <div className="personal_status font159">
             {List.remark}
            </div>
          </div>
        </div>
        <div className="phone space font15" onClick={() => {
            // console.log(List.businessPhoneNumber)
            window.location.href = `tel:${List.phoneNumber}`;
          }} >
          <div>联系商家</div>
          <img className="tel" src="https://changchong.oss-cn-hangzhou.aliyuncs.com/taro/mine/charge_pay_enter.png" />
        </div>
        {
          List.status == 1 && <div className="order_btn">
          <div className="btn" onClick={() => this.setMoney()}>
            去支付
          </div>
        </div>
        }
        {
          List.status == 2 && <div className="order_btn">
          <div className="btn" onClick={() => this.setRefund()}>
            去退款
          </div>
        </div>
        }
        {
          Refund && <Modal 
          type={'text'}
          list={[{ word:'请联系门店进行退款'}]}
          confirm="联系门店"
          confirmBtn={()=>{this.confirmBtn()}}
          ></Modal>
        }
        {
          Pickup && <Modal 
          type={'text'}
          list={[{ word:`取货码：${verifyInfo}`}]}
          confirm="知道了"
          confirmBtn={()=>{this.confirmPickup()}}
          ></Modal>
        }
      </div>
    )
  }
}

export default orderDetails
