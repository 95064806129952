import React, { Component } from 'react'
import './balanceCharge.scss';
import { MoneyList } from '@/components';
import { environment, getUrlConcat } from '@/utils/commons';
import classnames from 'classnames';

export class balanceCharge extends Component {
  state = {
    chargeMoney: '',
  }
  componentDidMount() {
    
  }
  // 余额充值
  balancePay =()=>{
    const { chargeMoney } = this.state;
    if(!chargeMoney) return;
    const params = {
      price: chargeMoney.toString(),
      acticityId: '0',
      activitySchemeId: '0',
      present: '0'
    }
    this.navigateTo('/pages/moneyPay/index',params)
  }
  navigateTo =(url, params={})=>{
    environment().then((env)=>{
      if(env === 'alipay' || env ===  'alipay_mini'){
        window.my.navigateTo({
          url: `${url}${ Object.keys(params).length !== 0? (getUrlConcat(params)):''}`
        })
        return;
      } else if(env === 'weapp_mini'){
        window.wx.miniProgram.navigateTo({
          url: `${url}${Object.keys(params).length !== 0? (getUrlConcat(params)):''}`
        })
        return;
      } else if(env === 'weapp'){
        window.history.go(-1)
      } else if(env === 'h5'){
        window.history.go(-1)
      }
    });
  }
  render() {
    const { chargeMoney } = this.state;
    return (
      <div className='balanceCharge_container'>
        <div className='balanceCharge_box'>
          <div className='balanceCharge_info'>
            <h6 className='Balance_title'>请选择充值金额</h6>
            <div className='balanceCharge_Balance'>
              <MoneyList 
                List={[30,50,100,200,300,6]}
                itemMoney={(money)=>{
                  this.setState({
                    chargeMoney: money,
                  })
                }}
              />
            </div>  
          </div>
          <button
            onClick={()=>{this.balancePay()}} 
            className={classnames('balanceCharge_btn',{'active':!chargeMoney})}
          >确认充值</button>
        </div>
      </div>
    )
  }
}

export default balanceCharge
