import React from 'react';
import ReactDOM from 'react-dom';
import 'lib-flexible';
import 'reset-css';
import App from './router/index';
import { Provider } from 'react-redux';
import store from './store';
import '@/assets/css/base.scss';
import { HashRouter, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <Route component={App}/>
    </HashRouter>
  </Provider>, 
  document.getElementById('root'));

serviceWorker.unregister();
