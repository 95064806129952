import tradinglist from '@/pages/tradingRecord/tradinglist';
import tradingInfo from '@/pages/tradingInfo/tradingInfo';
import favorite from '@/pages/favorite/favorite';
import myCoupon from '@/pages/myCoupon/myCoupon';
import accountBalance from '@/pages/accountBalance/accountBalance';
import balanceCharge from '@/pages/balanceCharge/balanceCharge';
import payActive from '@/pages/payActive/payActive'
import chargeDetails from '@/pages/chargeDetails/chargeDetails'
import advertising from '@/pages/advertising/advertising'
import newyearActive from '@/pages/newyearActive/newyearActive'
import updateversion from '@/pages/updateversion/updateversion'
import consumption from '@/pages/consumption/consumption'
import lowCarbonDetail from '@/pages/lowCarbonDetail/lowCarbonDetailwx'  // 低碳值使用说明
//import lowCarbonDetailzfb from '@/pages/lowCarbonDetail/lowCarbonDetailzfb'  // 低碳值使用说明支付宝
import lowCarbonDetailzfb from '@/pages/lowCarbonDetail/lowCarbonDetailzfb'  // 低碳值使用说明支付宝
import powerGraph from '@/pages/powerGraph/powerGraph'  // 功率曲线图
import dragonBoatFestival from '@/pages/dragonBoatFestival/dragonBoatFestival'  // 端午活动
import goodsOrderConfirm from '@/pages/goodsOrderPay/goodsOrderPay'  // 商品订单确认
import goodsConsigneeInfo from '@/pages/goodsConsigneeInfo/goodsConsigneeInfo'  // 商品订单确认
import insureExplanation1 from '@/pages/insureExplanation/insureExplanation'  // 保险说明
import abcPayFinish from '@/pages/abcPayFinish/abcPayFinish'  // 农行支付完成

import order from '@/pages/order/order'  // 商品订单
import orderDetails from '@/pages/orderDetails/orderDetails'  // 订单详情
import machineTradingInfo from '@/pages/machineTradingInfo/machineTradingInfo'  // 订单详情
import yzads from '@/pages/yzads/yzads'  // 云纵广告
import h5go from '@/pages/h5go/h5go'  // 关注公众号 跳转小程序
const routers = [
  {
    path: "/tradinglist",
    component: tradinglist,
    title:'交易记录'
  },
  {
    path: "/tradingInfo",
    component: tradingInfo,
    title:'交易详情'
  },
  {
    path: "/favorite",
    component: favorite,
    title:'我的收藏'
  },
  {
    path: "/myCoupon",
    component: myCoupon,
    title:'我的优惠'
  },
  {
    path: "/accountBalance",
    component: accountBalance,
    title: "账户余额",
  },
  {
    path: "/balanceCharge",
    component: balanceCharge,
    title: "余额充值",
  },
  {
    path: "/payActive",
    component: payActive,
    title: "感恩大赠送",
  },
  {
    path: "/chargeDetails",
    component: chargeDetails,
    title: "常见问题",
  },
  {
    path: "/newyearActive",
    component: newyearActive,
    title: "充值大放送",
  },
  { 
    path: "/advertising",
    component: advertising,
    title: "",
  },
  {
    path:"/updateversion",
    component:updateversion,
    title:'点点畅行'
  },
  {
    path:"/consumption",
    component:consumption,
    title:'消费金明细'
  },
  {
    path:"/lowCarbonDetailzfb",
    component:lowCarbonDetailzfb,
    title:'使用说明'
  },
  {
    path:'/lowcarbonDetail',
    component:lowCarbonDetail,
    title:'使用说明'
  },
  {
    path:'/powerGraph',
    component:powerGraph,
    title:'实时功率'
  },
  {
    path:'/dragonBoatFestival',
    component:dragonBoatFestival,
    title:'活动详情'
  },
  {
    path:'/goodsorderconfirm',
    component:goodsOrderConfirm,
    title:'订单确认',
  },
  {
    path:'/consigneeinfo',
    component:goodsConsigneeInfo,
    title:'联系人信息'},
  {
    path:'/order',
    component:order,
    title:'商品订单'
  },
  {
    path:'/orderDetails',
    component:orderDetails,
    title:'订单详情'
  },
  {
    path:'/machineTradingInfo',
    component:machineTradingInfo,
    title:'订单详情'
  },
  {
    path:'/yzads',
    component:yzads,
    title:'广告'
  },
  {
    path:'/insureExplanation',
    component:insureExplanation1,
    title:'关于充电安全保障'
  },
  {
    path:'/abcpayfinish',
    component: abcPayFinish,
    title:'支付结果'
  },
  {
    path:'/h5go',
    component:h5go,
    title:'公众号'
  }
];
export default routers;