import React, { Component } from 'react';
import { queryUserOrderList } from '@/api/api';
import { isEnv } from "@/config/index";
import { urlResolver } from '@/utils/commons';
import { checkisEnv } from '@/utils/utils'
import InfiniteScroll from 'react-infinite-scroller'
import classnames from 'classnames';
import Modal from '../../components/modal/modal'
import './order.scss';
import qs from 'qs'
export class order extends Component {
    state = {
      List:[],//收藏列表
      total:0,
      page: 1,
      current: null,//当前
      hasMore: true, // 是否开启下拉加载
      pageSize:4,
      Refund:false,
    }
  componentDidMount() {
    if (!isEnv) {
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6IjE4NzkwODA3MTgwIiwidXNlck1vYmlsZSI6IjE4NzkwODA3MTgwIiwidXNlcklkIjozOTk3MTQxLCJvcGVuSWQiOiJvcW1nRjVsM0Ntd2syTE1KZm5qYmVzMGxTOHc0In0.mCmgaef7O1vGy1_RHICLLYTCRoy5OhM5dUICjOyW210";
      urlResolver(`?token=${token}`)
    } else {
      let url = this.props.location.search;
      urlResolver(url);
    }
    this.loadFunc()
  }
  
  payment(item) {
    const params = {
      activityId: item.orderId,
      sourceType: 'again',
      money:item.money
    }
    console.log(params,'商品列表')
    checkisEnv().then(res => {
      console.log('=======',res)
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          window.my.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
        case 'weapp_mini':
          window.wx.miniProgram.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(params)}`
          })
          break
      }
    })
  }
  shopstatus(status) {
    if(status == 1 ) {
      return '待支付'
    }else if(status == 2 ) {
      return '已支付'
    }else if(status == 3 ) {
      return '退款'
    }else if(status == 4 ) {
      return '订单关闭'
    }
  }
  details(item) {
    this.props.history.push({pathname:`/orderDetails`,state:item.orderId,verifyInfo:item.verifyInfo});
  }
  // 获取我的订单列表所有数据
  loadFunc = (page = 1) => {
    const { pageSize, List } = this.state
    queryUserOrderList({
      pageNo:page,
      pageSize:pageSize
    }).then(({ success, data }) => {
      if (success) {
        this.setState({
          List: [...List,...data.records],
          total:data.total,
          page: page+1,
        })
      }
    })
  }
  nextPageFun (){
    const { page,  total, List } = this.state;
    if (total>=0 && (List.length >= total)) {
      return false
    } else{
      this.loadFunc(page);
    }
  }
  setpickup(item) {
    this.setState({
      verifyInfo:item.verifyInfo,
      Refund:true
    })
  }
  confirmBtn() {
    this.setState({
      Refund:false
    })
  }
  render() {
    let { List, Refund, verifyInfo, total } = this.state
    return (
      <div className="order">
        <div className="ss" onClick={() => this.payment()}></div>
        {
          List.length != 0 ?<InfiniteScroll
          initialLoad={false} // 不让它进入直接加载
          pageStart={1} // 设置初始化请求的页数
          loadMore={this.loadFunc}  // 监听数据请求
          hasMore={true} // 是否继续监听滚动事件 true 监听 | false 不再监听
          useWindow={false} // 不监听 window 滚动条
          loader={total <= List.length ? <div className="loader" key='index'>没有更多数据了</div> :<div className="loader" key='index'>加载更多...</div>}
        >
          {
            List.map((item,index) =>{
              return (
                <div className="order_item" key={item.orderId + 'orderitem'} >
                  <div className="order_head" onClick={() => this.details(item)}>
                    <div className="order_name">
                      {item.tenantName}
                    </div>
                    <div className={classnames("order_state",item.status == 1 ? 'orange' : item.status == 1?'blue':'gray')}>
                      {this.shopstatus(item.status)}
                    </div>
                  </div>
                  <div className="order_border" onClick={() => this.details(item)}></div>
                  <div className="order_details" onClick={() => this.details(item)}>
                    <div className="order_details_shop">
                      {
                        item.marketUserOrderDetailList.length > 0 && item.marketUserOrderDetailList.map((i,v) =>{
                          return (
                            <img className="shop_img" key={i.id + 'shop_img'} src={i.goodsImgUrl.split(',')[0]}/>
                          )
                        })
                      }
                    </div>
                    <div className="order_tetalis_num">共{item.goodsNumber}件</div>  
                  </div>
                  <div className="time" onClick={() => this.details(item)}>{item.updateTime}</div>
                  <div className="money" onClick={() => this.details(item)}>
                    <div className="money_num">
                      {
                        item.money && <span>￥{item.money.toFixed(2)}</span>
                      }
                    </div>
                    <div className="money_name">
                      合计实付：
                    </div>
                  </div>
                  {
                    item.deliveryType == 1 && item.status == 2 && <div className="pickup">
                      <div className='pickup-btn' onClick={() => this.setpickup(item)}>取货码</div>
                    </div>
                  }
                  {
                    item.status == 1 && <div className="unpaid"> 
                      <div className="unpaid_L" onClick={() => this.details(item)}></div>
                      <div className="patfor btn" onClick={ () => this.payment(item)}>去支付</div>
                    </div>
                  }
                </div>
              )
            })
          }
        </InfiniteScroll> :<div className="img_box">
          <img className="img" src="https://changchong.oss-cn-hangzhou.aliyuncs.com/h5/map/new_none.png" />
          暂无订单
        </div>
        }
        {
          Refund && <Modal 
          type={'text'}
          list={[{ word:`取货码：${verifyInfo}`}]}
          confirm="知道了"
          confirmBtn={()=>{this.confirmBtn()}}
          ></Modal>
        }
      </div>
    )
  }
}

export default order
