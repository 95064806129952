import classNames from 'classnames'
import React, { Component } from 'react'
import { Toast } from '@/components';
import { setSession, getSession, removeSession } from '@/utils/commons';
import './h5go.scss'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveConsigneeInfo } from '@/store/actions';
import { isEnv } from "@/config/index";
import { urlResolver, getUrlConcat } from '@/utils/commons';
import { checkisEnv } from '@/utils/utils'
import qs from 'qs'
import { getGoodsDeliveryAddress, queryTenantWxPublicTicket } from '@/api/api';

const mapStateToProps =(state)=> {
  return {
    consigneeInfo: state.consigneeInfo
  };
}
const mapDispatchToProps=(dispatch)=> {
  return {
    saveConsigneeInfo: (info) => dispatch(saveConsigneeInfo(info))
  };
}
class H5go extends Component {
  constructor(props){
    super(props)
    this.state = {
      h5:false,
      attention:false,
    }
  }
  
  componentDidMount() {
    let data
    let url = this.props.location.search;
    const res = urlResolver(url);
    console.log("32313123232", res)
    // const params = res.arr ? JSON.parse(res.arr) : {};
    data =  res || null;
    console.log("32313123232", data)
    // this.setState({
    //   tissue:data.tissue
    // })
      // 测试商户ID  13414
      this.queryTenantWxPublicTicket()
  }
  queryUrlParam = (name, url) => {
    url = url.split("#")[0]
    const params = url.split("?")[1];
    if (!params) {
      return "";
    }
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const obj = params.match(reg);
    if (!obj) {
      return "";
    }
    return obj[2];
  }
  urlEncode = (str) => {
    str = (str + "").toString();
     return encodeURIComponent(str).replace(/!/g, "%21")
                                    .replace(/'/g, "%27")
                                    .replace(/\(/g, "%28")
                                    .replace(/\)/g, "%29")
                                    .replace(/\*/g, "%2A")
                                    .replace(/%20/g, "+");
  }
  queryTenantWxPublicTicket = () => {
    console.log(1)
    const url = window.location.href;
    const companyInfo = urlResolver(url.split("#")[0]);
    const companyName = companyInfo.companyName ? decodeURI(companyInfo.companyName) : '永意充电';
    companyInfo.companyName = companyName;
    document.title = companyName;
    // local.companyInfo = companyInfo;
    const styleType = this.queryUrlParam('styleType', url);
    // local.styleType = styleType;
    this.patternType = styleType === "" ? 1 : Number(styleType);
    if (!this.queryUrlParam('code', url)) {
      // 不是在浏览器运行 且 url中没有 code
      console.log('费浏览器')
      this.compileUrl(url, companyInfo)
    } else {
      const openId = storage.openId;
      const code = this.queryUrlParam('code', url)
      const tenantId = this.queryUrlParam('tenantId', url)
      console.log('openId===',openId,'code===',code,)
      console.log('tenantId====',tenantId)
      // alert("openId:"+openId + ", code:" + code)
      if (!openId && code) {
        if (tenantId === "") {
          storage.isDirectReceipt = false
          // 非直接到账 ~~~ 获取 openid
          console.log('非直接到账')
          // getOpenId(code).then(({ success, data }) => {
          //   if (success && data) {
          //     storage.openId = data
          //     // this.setRoute()
          //   }
          // })
        } else {
          storage.isDirectReceipt = true
          console.log('直接到账')
          // 直接到账 ~~~ 获取 openid
          // directlyOpenId(code, tenantId).then(({ success, data }) => {
          //   if (success && data) {
          //     storage.openId = data
          //     // this.setRoute()
          //   } else {
          //     util.showError("没有获取到openId")
          //   }
          // })
        }
      } else {
        // 这一步时为了判断有的商户会突然由非直接到账转为直接到账这里链接就会发生改变 openid要重新获取
        console.log('已经有openid了=====', openId)
        // this.setRoute()
      }
    }
    // let query = {
    //   id:'13414'
    // }
    // queryTenantWxPublicTicket(query).then(({success,data,msg}) => {
    //   if(success) {
    //     console.log(data)
    //   }
    // })
  }
  goto() {
    document.getElementById('goToMiniProgram').addEventListener('click', function() {
      // 确保在微信环境中
      if (typeof wx !== 'undefined') {
          wx.miniProgram.navigateTo({
              url: '/path/to/page?query=1' // 小程序中页面路径及查询参数
          });
      } else {
          alert('请在微信环境中打开H5页面！');
      }
    });
  }
  /**
 * 编译公众号url 
 * companyName=项目&logoUrl=图片名称&serviceTelephone=电话&serviceType=1&subTitle=logo下的名称 1 显示 2不显示
 * &scope=1 授权获取用户昵称 省市区 头像
 */
  compileUrl = (urls, obj) => {
    
    console.log('compileUrl-urls ===', urls)
    console.log('compileUrl-obj ===', obj)
    
    // const appid = urlResolver(urls).gzhId || gzhAppId;
    // console.log('compileUrl-appid ===', appid)
    
    const companyName = encodeURI(obj.companyName);
    obj.companyName = companyName;
    obj.t = new Date().getTime();
    let tenantId = this.queryUrlParam('tenantId',urls)
    
    // let redirectUri = urls.split("?")[0] + `?${qs.stringify(obj)}`;
    let redirectUri = 'https://api.saasin.hzchaoxiang.cn/Content/weixin/index.html' + `?${qs.stringify(obj)}`;
    redirectUri = this.urlEncode(redirectUri);
    // 请求公众号code
    const queryStr = `appid=wx81527f0d3a9d8d0a&redirect_uri=https://api.saasin.hzchaoxiang.cn/Content/weixin/index.html&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
    
    let url = "https://open.weixin.qq.com/connect/oauth2/authorize?" + queryStr;
    
    return window.location.replace(url)
  }
  setGoto() {
    location.href = "weixin://dl/business/?appid=wx825203fb02f1d991&path=pages/tab/index/index"
  }
  render() {
    const { attention } = this.state
    let style = {
      width: '200px',
      height: '45px', 
      textAlign: 'center',
      fontSize: '17px', 
      display: 'block', 
      margin: '0 auto', 
      padding: '8px 24px',
      border: 'none',
      borderRadius: '4px', 
      backgroundColor: '#07c160',
      color:'#fff',
    }
    return (
      <div>
        {//未关注公众号
          attention && <div className="h5go">
          <div className='head'>关注公众号为您提供更多服务</div>
          <img className='img' src={require('../../assets/img/gzhimg.jpg')}  />
          <div className='text'>长按二维码,识别关注</div>
          <div className="course">
            关注后，扫码充电,再点击扫一扫完成充电
          </div>
        </div>
        }
        {
          !attention && <div className="h5go">
          <div className="btn" onClick={() =>this.setGoto()}>
            
            跳转小程序
            {/* <wx-open-launch-weapp id="launch-btn" style={{width:'100%',height:'100%'}} username="gh_93200c9e6f69" path="pages/tab/index/index"> 
    <script type="text/wxtag-template">
        <button onClick={() =>this.setGoto()} style={style}>打开小程序</button>
    </script>
</wx-open-launch-weapp> */}
          </div>
          
        </div>
        }
      </div>
      
    )
  }
} 
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(H5go);   