import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEnv } from "@/config/index";
import './machineTradingInfo.scss';
import { urlResolver, payModel, stateModel } from '@/utils/commons';
import { offlineCardOrderDetail } from '../../api/api'
import { checkisEnv } from '@/utils/utils'
import qs from 'qs'

function mapStateToProps(state) {
  return {

  };
}
class machineTradingInfo extends Component {
  state = {
    infoData: {},//交易详情
    List:{}
  }
  componentDidMount(){
    if(!isEnv){
      // let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6IjE4NzkwODA3MTgwIiwidXNlck1vYmlsZSI6IjE4NzkwODA3MTgwIiwidXNlcklkIjozOTk3MTQxLCJvcGVuSWQiOiJvcW1nRjVsM0Ntd2syTE1KZm5qYmVzMGxTOHc0In0.mCmgaef7O1vGy1_RHICLLYTCRoy5OhM5dUICjOyW210";
      let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6InljMzYyNDEwNTYiLCJ1c2VyTW9iaWxlIjoiMTgyNTgxNzU4NDAiLCJ1c2VySWQiOjE1ODY4NDR9.0kE4swArLn29P40K_xzyL5bfmtv_CjcalY1T2s579-I";
      urlResolver(`?token=${token}`)
    } else {
      let url = this.props.location.search;
      const res = urlResolver(url);
      // console.log("32313123232", url)
      const params = res.arr ? JSON.parse(res.arr) : {};
    }
    this.initialize()
  }
  initialize() {
    let query = {
      orderId:urlResolver(this.props.location.search).orderId || urlResolver(this.props.location.search).id
    }
    console.log(urlResolver(this.props.location.search))
    offlineCardOrderDetail(query).then(({success,data}) => {
      if(success) {
        this.setState({
          List: data
        })
      }
    })
  }
  setBtn() {
    
    let { List:{status} } = this.state
    // if(status == 0) {
      checkisEnv().then(res => {
        console.log(res)
        switch (res) {
          case 'alipay':
          case 'alipay_mini':
            window.my.switchTab({
              url: `/pages/tab/index/index?${qs.stringify()}`
            })
            break
          case 'weapp':
          case 'weapp_mini':
            // window.wx.miniProgram.navigateTo({
            //   url: `/pages/tab/index/index?${qs.stringify()}`
            // })
            window.wx.miniProgram.switchTab({
              url: `/pages/tab/index/index?${qs.stringify()}`
            })
            break
        }
      })
  }
  render() {
    const { List = {} } = this.state
    return ( 
      <div className='tradingInfo_container'>
        {/* <div>
          <button>点击</button>
        </div>
        <iframe className="tu" src="https://engine.issixx.com/index/activity?appKey=283Nqv4K34ckqr2hueYgV2zBe12o&adslotId=392934&uk_a1=__IMEI__&uk_a2=__IMEI2__&uk_a3=__MUID__&uk_b1=__IDFA__&uk_b2=__IDFA2__&uk_c1=__OAID__"></iframe> */}
        {
          List.id && <div className='tradingInfo_box'>
          <div className='Info_money'>
            {/* <h3 className='money_Num'>{`${true?'+':'-'}${(Number(Money) + Number(Bao)).toFixed(2)}`}</h3> */}
            <h3 className="money_Num">{(List.money/100).toFixed(2)}</h3>
            <h6 className='money_explain'>
              {`充值${List.status == 0?'成功':List.status == 1?'失败':'异常'}`}
              {
                List.status == 1 && <div className="red">({List.downCardRefundOpen?'您的退款将原路返回':'请联系商户处理'})</div>
              }
              {
                List.status == 2 && <div className="red">(请联系商户为您退款)</div>
              }
            </h6>
            <ul className='money_icon'>
              <li className='icon_left'></li>
              <li className='icon_right'></li>
            </ul>
          </div>
          <ul className='info_list'>
            <li className='list_item'>
              <span className='item_lable'>卡内余额</span>
              <span className='item_word'>{List.status == 0 ? (List.newCardMoney/100).toFixed(2):(List.oldCardMoney/100).toFixed(2)}元</span>
            </li>
            <li className='list_item'>
              <span className='item_lable'>设备号</span>
              <span className='item_word'>{List.deviceNumber}</span>
            </li>
            <li className='list_item'>
              <span className='item_lable'>卡号</span>
              <span className='item_word'>{List.userCardKey}</span>
            </li>
            <li className="list_item_cross"></li>
            <li className='list_item'>
              <span className='item_lable'>付款方式</span>
              <span className='item_word'>{List.payType == 1 ?'微信':'支付宝'}</span>
            </li>
            <li className='list_item'>
              <span className='item_lable'>支付说明</span>
              <span className='item_word'>线下卡充值机支付</span>
            </li>
            <li className='list_item'>
              <span className='item_lable'>交易时间</span>
              <span className='item_word'>{List.createTime.slice(5)}</span>
            </li>
            <li className='list_item'>
              <span className='item_lable'>订单编号</span>
              <div className='item_word'>{List.orderNo}</div>
            </li>
          </ul>
        </div>
        }
        {
          List.status != 2 && <div className="btn" onClick={() => this.setBtn()}>{List.status == 0 ? '返回首页': '重新扫码' }</div>
        }
        {
          List.status == 2 && <div className="btn" ><a href={'tel:'+List.phoneNumber}>联系商户</a></div>
        }
        
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(machineTradingInfo);