import React, { Component } from 'react'
import './yzads.scss';
import { isEnv } from "@/config/index";
import { urlResolver } from '@/utils/commons';
import { checkisEnv } from '@/utils/utils'
import qs from 'qs'
export class yzad extends Component {
  state = {
    url:'',
    urlData:{}
  }
  componentDidMount(){
    if( !isEnv ) {
      this.setState({
        urlData: urlResolver(this.props.location.search)
      })
    }
    let url = this.props.location.search;
    let arr = urlResolver(url)
    this.setState({
      urlData:arr
    })
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          this.setState({
            url: `https://engine.issixx.com/index/activity?appKey=283Nqv4K34ckqr2hueYgV2zBe12o&adslotId=398795&uk_a1=__IMEI__&uk_a2=__IMEI2__&uk_a3=__MUID__&uk_b1=__IDFA__&uk_b2=__IDFA2__&uk_c1=__OAID__`
          })
          break
        case 'weapp_mini':
          this.setState({
            url:'https://engine.issixx.com/index/activity?appKey=283Nqv4K34ckqr2hueYgV2zBe12o&adslotId=390153&uk_a1=__IMEI__&uk_a2=__IMEI2__&uk_a3=__MUID__&uk_b1=__IDFA__&uk_b2=__IDFA2__&uk_c1=__OAID__'
          })
          break
      }
    })
  }
  setindex() {
    let { arr } = this.state
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          window.my.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(arr)}`
          })
          break
        case 'weapp_mini':
          window.wx.miniProgram.navigateTo({
            url: `/pages/moneyPay/index?${qs.stringify(arr)}`
          })
          break
      }
    })
  }
  goHome() {
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          window.my.navigateTo({
            url: `/pages/index/index?${qs.stringify()}`
          })
          break
        case 'weapp_mini':
          window.wx.miniProgram.navigateTo({
            url: `/pages/index/index?${qs.stringify()}`
          })
          break
      }
    })
  }
  details() {
    let {urlData} = this.state
    let query 
    console.log(urlData,query)
    checkisEnv().then(res => {
      switch (res) {
        case 'alipay':
        case 'alipay_mini':
          if(urlData.type == 9) {
            query = {
              TranId:urlData.id,
              DeviceType:urlData.type
            }
            window.my.navigateTo({
              url: `/pages/DcChargeState/index?${qs.stringify(query)}`
            })
          }else if(urlData.type == 3){
            query = {
              TranId:urlData.id,
              DeviceType:urlData.type
            }
            window.my.navigateTo({
              url: `/pages/DcChargeState/index?${qs.stringify(query)}`
            })
          }else{
            query = {
              id:urlData.id,
              deviceType:urlData.type
            }
            window.my.navigateTo({
              url: `/pages/chargeitem/index?${qs.stringify(query)}`
            })
          }
          break
        case 'weapp_mini':
          if(urlData.type == 9) {
            query = {
              TranId:urlData.id,
              DeviceType:urlData.type
            }
            window.wx.miniProgram.navigateTo({
              url: `/pages/DcChargeState/index?${qs.stringify(query)}`
            })
          }else if(urlData.type == 3){
            query = {
              TranId:urlData.id,
              DeviceType:urlData.type
            }
            window.wx.miniProgram.navigateTo({
              url: `/pages/DcChargeState/index?${qs.stringify(query)}`
            })
          }else{
            query = {
              id:urlData.id,
              deviceType:urlData.type
            }
            window.wx.miniProgram.navigateTo({
              url: `/pages/chargeitem/index?${qs.stringify(query)}`
            })
          }
          break
      }
    })
  }
  render() {
    return (
      <div className='yzad_container'>
        <div className='yzad_box'>
          <button className="btn" onClick={() => this.goHome()}>跳转首页</button>
          <button className="btn" onClick={() => this.details()}>查看充电详情</button>
        </div>
        <iframe className="img_ad"  src={this.state.url} >
        </iframe>
      </div>
    )
  }
}

export default yzad
