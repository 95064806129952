import net from "./net";
import { order, business, newOrder, newBusiness,newDevice, newMarketing } from "@/config/index";
// console.log('默认', order, device, newDevice, newOrder )

// const get = async () => {
//   const data = await net.get();
//   return data;
// };
// 交易记录
const tradingRecord = async (Page) => {
  const data = await net.post(order + "v1/my/AppletsTransrecord", {
    Page: Page,
  });
  return data;
};
// 我的所有的设备收藏列表
const collectionList =async ( Page ) => {
  const data = await net.post( business+'v1/my/Favoritesrecord', {
    Page: Page
  });
  return data;
};
// 取消设备收藏
const cancelCollect =async ( Id ) => {
  const data = await net.post( business + 'v1/my/CancelFavorites', {
    Id:Id
  });
  return data;
};
// 我的优惠列表
const getMyCardPackage = async () => {
  const data = await net.get(newBusiness+'user/userCard/applet/myCardPackage');
  return data;
};
// 活动列表

const getPlatformUserActivity = async () => {
  const data = await net.get(newBusiness+'user/app/queryPlatformUserActivity');
  return data;
};
// 消费金变动明细
const queryConsumeFundRecords = async (id) => {
  const data = await net.post(newMarketing+'marketing/user/applet/queryConsumeFundRecords', {
    activityId:id
  });
  return data;
};
//功率曲线图
const queryChargePower = async (obj) => {
  const data = await net.get('api-log/collect/applet/queryChargePower', {
    orderId:obj.orderId,
    deviceNumber:obj.deviceNumber,
    startTime:obj.startTime,
    endTime:obj.endTime,
  });
  return data;
};
const getGoodsDeliveryAddress = () => {
  return net.get(newMarketing + 'user/delivery/applet/getByUser')
};
const saveGoodsDeliveryAddress = (data) => {
  return net.postJson(newMarketing+'user/delivery/applet/saveOrUpdate', data);
}

//订单列表
const queryUserOrderList = async (obj) => {
  const data = await net.get(newMarketing+'user/order/applet/queryUserOrderList', {
    pageNo:obj.pageNo,
    pageSize:obj.pageSize
  });
  return data;
};
//订单列表
const queryUserOrderDetail = async (obj) => {
  const data = await net.get(newMarketing+'user/order/applet/queryUserOrderDetail', {
    orderId:obj.id,
  });
  return data;
};
//线下卡订单详情
const offlineCardOrderDetail = async (obj) => {
  const data = await net.get(newOrder+'order/applet/offlineCardOrderDetail', {
    orderId:obj.orderId,
  });
  return data;
};
//线下卡订单详情
const offlineCardOrderList = async (pageNo) => {
  const data = await net.get(newOrder+'order/applet/offlineCardOrderList', {
    pageSize:10,
    pageNo:pageNo
  });
  return data;
};
const myBalanceRefundList = async (pageNo) => {
  const data = await net.get(newBusiness+'user/applet/myBalanceRefundList', {
    pageSize:10,
    pageNo:pageNo
  });
  return data;
};
//商品详情
const queryGoodsDetails = async (obj) => {
  const data = await net.get(newMarketing+'user/queryGoodsDetails', {
    id:obj.id,
  });
  return data;
};
const queryTenantWxPublicTicket = async (obj) => {
  const data = await net.get('api-open/wx/public/account/queryTenantWxPublicTicket', {
    tenantId:obj.id,
  });
  return data;
};
export { 
  tradingRecord,
  collectionList,
  cancelCollect,
  getMyCardPackage,
  getPlatformUserActivity,
  queryConsumeFundRecords,
  queryChargePower,
  getGoodsDeliveryAddress,
  saveGoodsDeliveryAddress,
  queryUserOrderList,
  queryUserOrderDetail,
  offlineCardOrderDetail,
  offlineCardOrderList,
  myBalanceRefundList,
  queryGoodsDetails,
  queryTenantWxPublicTicket,
};