import React, { Component } from 'react'
import { environment } from '@/utils/commons';

export class abcPayFinish extends Component {
  state = {
  }
  componentDidMount() {
    this.navigateTo('/pages/tab/index/index')
  }

  navigateTo =(url, params={})=>{
    environment().then((env)=>{
      if(env ===  'alipay_mini'){
        window.my.switchTab({
          url: url
        })
        return;
      } else if(env === 'weapp_mini'){
        window.wx.miniProgram.switchTab({
          url: url
        })
        return;
      }
    });
  }
  render() {
    return (
      <div></div>
    )
  }
}

export default abcPayFinish