let defaultState = {
  userInfo: {
    AvatarUrl:'',
    ExistPassWord:'',
    Moblie:'',
    Name:'',
    Token:'',
  },
  //账户信息
  acountData: {
    TotalAccount:{
      AccountMoney: 0,
      Bao: 0,
      Coupon: 0,
      Mobile: "",
      Points: 0,
      UserName: ""
    },
    TotalCharge:{
      TotalHour: null,
      TotalTimes: null
    },
    userActivityReturn:{
      IsActivity: null,
      UseFrequency: null,
    },
  },
  // 商品收货人信息
  consigneeInfo: {
    isDefault: 1, // 是否默认：0-否，1-是
    contacts: "",
    phoneNum: '',
    gender: 0, // 性别：0-未知，1-男，2-女
    address: '',
    houseNum: ''
  },
};
const incrementReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "USERINFO": {
      return {
        ...state,
        userInfo: {...state.userInfo,...action.userInfo},
      };
    }
    case "ACOUNTDATA": {
      return {
        ...state,
        acountData: {...action.acountData},
      };
    }
    case "CONSIGNEE_INFO": {
      return {
        ...state,
        consigneeInfo: {...action.consigneeInfo},
      };
    }
    default:
      return state;
  }
};
export default incrementReducer;
