const saveUserInfo = (userInfo) => {
  return {
    type: "USERINFO",
    userInfo,
  };
};
const saveAcountData = (acountData) => {
  return {
    type: "ACOUNTDATA",
    acountData,
  };
};
const saveConsigneeInfo = (consigneeInfo) => {
  return {
    type: "CONSIGNEE_INFO",
    consigneeInfo,
  };
};
export { saveUserInfo, saveAcountData, saveConsigneeInfo };