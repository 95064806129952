import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { payModel, stateModel } from '@/utils/commons';
import './itemList.scss'
//
///
function mapStateToProps(state) {
  return {

  };
}

class itemList extends Component {
  static propTypes = {
    itemData: PropTypes.object.isRequired,
    itemFun: PropTypes.func.isRequired,
  }
  static defaultProps = {
    itemData: {},
  }
  state = {

  }
  // 订单状态
  orderState =()=>{
    const { itemData:{TransactionType, State} } = this.props;
    if(TransactionType === 1){// true +   false -
      return true;
    } else {
      if(State === 2){
        return true;
      } else {
        return false;
      }
    }
  }
  refund(type) {
    switch(type) {
      case 1:
        return '已通过'
      case 2:
        return '未通过'
      case 3:
        return '审核中'
      default:
        return '系统出错'
    }
  }
  render() {
    let { itemData:{Bao, CreateTime, createTime,Money,money, TransactionType, Ways,payStatus,payType,applyType,status,applyMoney}, index } = this.props;
    return (
      <div className='itemRecord' onClick={()=>this.props.itemFun()}>
        <div className='Record_info'>
          {
            index == 1 ? <h6 className='info_title' >{`${stateModel((Ways === 6 || Ways === 7)?Ways:TransactionType)}-${payModel(Ways)}`}</h6>
            :index == 2?<h6 className='info_title' >{`线下卡充值机-${payModel(payType)}`}</h6>
            :<h6 className='info_title' >{`${applyType == 1 ? '消费金':'余额'}退款`}</h6>
          }
          {
            index == 1 && CreateTime &&  <span className='info_time'>
              {CreateTime.slice(5)}
            </span>
          }
          {
            index == 2 && <span className='info_time'>
              {createTime.slice(5)}
            </span>
          }
          {
            index == 3 && <span className='info_time'>
              {createTime.slice(5)}
            </span>
          }
        </div>
        {
          index == 1 && <span className={classnames('Record_money',this.orderState()&&'active')}>
            {`${this.orderState()?'+':'-'}${(Money + Bao).toFixed(2)}`}
          </span>
        }
        {
          index == 2 &&<div>
            <span className={classnames('Record_money',this.orderState()&&'active')}>
              +{(money/100).toFixed(2)}
            </span>
            {
              payStatus == 2 && <h6 className="refund">已退款</h6>
            }
          </div>
        }
        {
          index == 3 &&<div>
            <span className={classnames('Record_money',this.orderState()&&'active')}>
              {(applyMoney/100).toFixed(2)}
            </span>
            <h6 className={classnames(status == 3 ? 'refunded' :status == 4 || status == 1 ?'refund':status == 5 ?'gyar':'audit')}>
              {
                status == 3 ? '已退款' :status == 4 || status == 1?'未通过': status == 5 ?'已取消':'审核中'
              }
            </h6>
          </div>
        }
        
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
)(itemList);