import React, {Component} from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames';
import { Modal } from '@/components';
import './moneyList.scss';

class moneyList extends Component {
  constructor(props) {
    super(props);
    this.state={
      moneyIndex: -1,
      isHandle: false,
      userDefined: 0,
    }
  }
  static propTypes = {
    List: PropTypes.array.isRequired, //列表内容
    itemMoney: PropTypes.func.isRequired,//点击某一个金额端口
    title: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
  }
  static defaultProps = {
    List: [1,2,3,4,5,6],
    title: '输入充值金额(元)',
    placeholder: '请输入 0.01-10000 的数字',
    maxLength: 5,
  }
  render () {
    const { List, title, placeholder, maxLength } = this.props;
    const { userDefined, moneyIndex, isHandle } = this.state;
    return (
      <ul className='money_list'>
        {
          List.map((item,index)=>
            <li className={classnames('list_item', moneyIndex === index&&'active')} 
              onClick={()=>{
                this.setState({
                  moneyIndex: index,
                  userDefined: index === 5?userDefined:0,
                  isHandle: index === 5
                })
                if(index!== 5){
                  this.props.itemMoney(item)
                } else {
                  this.props.itemMoney(0)
                }
              }}
              key={'money'+index}
            >
              {
                `${(index === 5)?(userDefined?(userDefined+'元'):'自定义'):(item)+'元'}`
              }
            </li>
          )
        }
        {isHandle && <Modal
          title={ title }
          placeholder= { placeholder }
          maxLength = { maxLength }
          cancel={'取消'}
          confirm={'确定'}
          cancelBtn={()=>{
            this.setState({
              isHandle: false,
              userDefined: 0,
            },()=>{
              this.props.itemMoney(0)
            })
          }}
          confirmBtn={(e)=>{
            this.setState({
              isHandle: false,
              userDefined: e
            },()=>{
              this.props.itemMoney(e)
            })
          }}
        />}
      </ul>
    )
  }
}

export default moneyList