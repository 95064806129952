import React, { Component } from 'react'
import './chargeDetails.scss';
export default class chargeDetails extends Component {
  render() {
    return (
      <div className="chargeDetails">
        <img
          src={require('../../assets/img/chargeDetails.png')}
          alt="暂无图片"
          className="chargeDetailsImg"
        />
      </div>
    )
  }
}
